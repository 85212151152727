<template>
  <div class="co-flex-col px-2">
    <template
      v-if="items.length"
    >
      <div
        class="co-flex-col co-justify-between"
        style="height: 930px;"
      >
        <v-list
          two-line
          color="white"
        >
          <v-list-item-group
            v-model="selected"
            active-class="primary--text"
          >
            <div
              v-for="(item, index) in items"
              :key="index"
              class="co-flex-col"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle
                    class="mt-2"
                  >
                    <div class="co-flex-row co-items-center">
                      <template
                        v-if="descs[item.state]"
                      >
                        <v-icon
                          v-if="descs[item.state].icon"
                          size="18"
                          :color="descs[item.state].color || 'blue-grey'"
                        >
                          {{ descs[item.state].icon }}
                        </v-icon>
                      </template>
                      <span class="co-text-xs co-text-gray-500 ml-2">{{ item.createTime }}</span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <pop-menu
                    :actions="rapids[item.state] || actions"
                    @action="onAction($event, index, item)"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < items.length - 1"
              />
            </div>
          </v-list-item-group>
        </v-list>
        <div
          v-if="pagi.length"
          class="d-flex flex-row justify-center"
        >
          <v-pagination
            v-model="pagi.current"
            :length="pagi.length"
            :total-visible="pagi.totalVisible"
            circle
            @input="changePagination($event)"
          />
        </div>
      </div>
    </template>
    <template
      v-else
    >
      <div class="co-flex-col co-justify-center co-items-center py-10">
        <v-icon
          size="100"
          color="blue-grey lighten-2"
        >
          {{ empty.icon || defIcon }}
        </v-icon>
        <span
          class="co-text-md blue-grey--text lighten-2 mt-4"
        >
          {{ empty.text || defText }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
  import web from '@/api/web/co.web'
  import mixPreset from '@/pages/mixins/my/mix.my.preset'

  const States = web.comm.States
  const Rapids = mixPreset.ArticleRapids

  export default {
    components: {
      PopMenu: () => import('@/pages/sections/comm/PopMenu.vue'),
    },
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      pagination: {
        type: Object,
        default: () => {
          return {
            current: 1,
            length: 0,
            totalVisible: 3
          }
        }
      },
      empty: {
        type: Object,
        default: () => {
          return {
            icon: 'mdi-cloud-alert-outline',
            text: '无数据',
          }
        }
      },
      actions: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        defIcon: 'mdi-cloud-alert-outline',
        defText: '无数据',
        selected: [],
        pagi: {},
        descs: web.comm.StateDescs,
        rapids: {
          [States.CREATE]: [
            Rapids.edit,
            Rapids.preview,
            Rapids.publish,
            Rapids.remove
          ],
          [States.PUBLISH]: [
            Rapids.edit,
            Rapids.down,
            Rapids.preview
          ],
          [States.DOWN]: [
            Rapids.edit,
            Rapids.publish,
            Rapids.preview
          ],
        }
      }
    },
    watch: {
      pagination: {
        handler (val) {
          this.pagi = {
            ...val
          }
        },
        immediate: true
      }
    },
    methods: {
      onAction (ev, index, item) {
        this.$emit('action', {
          rapid: {
            ...ev.item
          },
          index,
          item
        })
      },
      changePagination (number) {
      },
    }
  }
</script>
